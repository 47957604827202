<template>
  <ion-page>
    <ion-content :fullscreen="true">
      <ion-list>
        <ion-item @click="handleLogout">
          <ion-label>로그아웃</ion-label>
        </ion-item>
      </ion-list>
    </ion-content>
  </ion-page>
</template>

<script>
import { 
  IonPage, 
  IonContent,
  IonItem, 
  IonLabel, 
  IonList,
  menuController
} from '@ionic/vue';
import { useRouter } from 'vue-router'
import { defineComponent } from 'vue';
import { useStore } from 'vuex'

export default defineComponent({
  name: 'My',
  components: {
    IonPage, 
    IonContent,
    IonItem, 
    IonLabel, 
    IonList
  },
  setup() {
    const store = useStore()
    const router = useRouter();

    // store actions
    const fetchLogout = () => store.dispatch('User/fetchLogout')
    
    const handleLogout = () => {
      fetchLogout().then(() => {
        menuController.close();
        router.push('/')
      })
    }

    return {
      handleLogout,

      // store actions
      fetchLogout
    }
  }
});
</script>

<style scoped>
:deep ion-item{
  --padding-start: 0;
}
:deep .item .sc-ion-label-ios-h{
  font-size: 14px;
}
</style>